import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const IphonePopUp = ({ open, handleClose }) => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        const chromeUrl = `googlechromes://www.winkee.dating/Main`;
        const Url = `www.winkee.dating`;
        const safariUrl = `x-web-search://?${encodeURIComponent(Url)}`;
        const fallbackUrl = '/?fb';

        // Try Chrome first
        window.location.href = chromeUrl;
  
        // If Chrome is not installed, try Safari
        setTimeout(() => {
            window.location.href = safariUrl;

            // If Safari doesn't work, fallback to HTTPS
            setTimeout(() => {
                navigate(fallbackUrl);
            }, 2000);
        }, 2000);

        // Call the handleClose prop to update the parent component's state
        handleClose();
    };

    const handleClosePopup = () => {
        handleClose();
        const fallbackUrl = '/?fb';
        
        // Set the URL to "/?fb" without refreshing
        setTimeout(() => {
            navigate(fallbackUrl);
        }, 200);
    };



    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle style={{direction:'rtl'}}>ברוך הבא!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <h1 style={{direction:'rtl'}}>בשביל להמשיך אנא פתח את הדפדפן בCHROME/SAFARI</h1>
                    <p style={{direction:'rtl'}}>או לחץ על ה3 נקודות במסך למעלה ואז "פתח בדפדפן"</p>
                    <div className="sub-login">
                        <button  onClick={handleClosePopup} className='log-button button0 button2'>
                            סגור
                        </button>
                        <button style={{marginLeft:'10px'}} onClick={handleRedirect} className='log-button button0 button2'>
                            עבור
                        </button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default IphonePopUp;