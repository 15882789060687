import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import HeartSpinner3 from './SpinnerHeart/HeartSpinner3';
import { styled } from '@mui/material/styles';

const AnimatedButton = styled(Button)`
  position: relative;
  overflow: visible;
  border: none;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: white;
    z-index: -1;
    border-radius:10px;

  }

  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: white;
    z-index: -2;
    filter: blur(30px);
    border-radius:10px;

  }

  &::before, &::after {
    background: linear-gradient(90deg, white, transparent, transparent);
    background-size: 200% 100%;
    animation: borderAnimation 3s linear infinite;
  }

  @keyframes borderAnimation {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
`;

export default function LoadingButtonsTransition({ onClick, loading,buttonLabel,style,isAnimated  }) {
  // const [isAnimated, setIsAnimated] = useState(true);
  const [delayedText, setDelayedText] = useState(false);
  const ButtonComponent = isAnimated ? AnimatedButton : Button;

  const handleClick = () => {
    if (!loading) {
      // Call the onClick function passed from props only if not loading
      onClick();
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsAnimated(false);
  //   }, 5500);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setDelayedText(true);
      }, 10000); // 10 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount
    } else {
      setDelayedText(false);
    }
  }, [loading]);


  return (
    <ButtonComponent
      variant="contained"
      // variant="outlined"
      style={{
        marginTop: '10px',
        fontFamily: '"Secular One", sans-serif',
        marginBottom:'30px',
        ...style
      }}
      disabled={loading}
      onClick={handleClick}
      startIcon={
        loading ? (
          // startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
      // startIcon={loading ? <HeartSpinner2 size={5}  />  : <SendIcon />}
          <HeartSpinner3  /> // Adjust vertical position of icon
        ) : (
          <SendIcon style={{ transform: 'rotate(180deg)' }} />
        )
      }
    >
      <span style={{ marginTop: loading ? '2px' : '0px' }}>
        {loading
          ? delayedText
            ? '...זה לוקח יותר זמן מהצפוי'
            : '...עובדים על זה'
          : buttonLabel}
      </span>
    </ButtonComponent>
  );
};
