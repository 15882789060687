import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import LogoutButton from './Auth0/Logout';
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0 hook
// import Icon from "../logo_white_text.png";
import Icon from "../winkee.png";
import coin from "../coin.png";
import InfinityStar from "../star-infinity.png";
import { RequestContext } from './RequestContext';
import { PiListHeartFill } from "react-icons/pi";
import { BsSearchHeartFill } from "react-icons/bs";
import { BsHearts } from "react-icons/bs";
import { MdForum } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QuickAddToHomePage from './QuickAddToHomePage';

const NavBar = ({ menuOpen, setMenuOpen }) => {
  const navigate = useNavigate();
  const { isAuthenticated,user,loginWithRedirect} = useAuth0(); // Get isAuthenticated from useAuth0 hook
  const {
    requestLeft,
    daysRemaining,
    hoursRemaining,

  } = useContext(RequestContext);

  const url = 'www.winkee.dating/Main';

  // Embedded browser check function
  const isEmbeddedBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const embeddedPatterns = ['instagram', 'fban', 'fbav', 'fbios', 'facebook','linkedin','fbclid','fc','tiktok'];
    return embeddedPatterns.some(pattern => userAgent.includes(pattern));
  };

  // Force external browser for embedded browsers
  const forceExternalBrowser = (url) => {
    const formattedUrl = url.startsWith('http') ? url : `https://${url}`;
    if (/iphone|ipad|ipod/i.test(navigator.userAgent)) {
      const safariUrl = `x-web-search://?${encodeURIComponent(formattedUrl)}`;
      window.location.href = safariUrl;
    } else if (/android/i.test(navigator.userAgent)) {
      window.location.href = `intent:${formattedUrl}#Intent;scheme=https;package=com.android.chrome;end`;
    } else {
      window.location.href = formattedUrl;
    }
  };

  useEffect(() => {
    console.log('useEffect: requestLeft:', requestLeft);
  }, [requestLeft]);

  const handleNavigate = () => {
    navigate("/Main"); // Navigate to the home page
  };

  const handleLinkClick = (event) => {
    // Close the menu when a link is clicked
    setMenuOpen(false);
    // Prevent the default behavior of the link
    // event.preventDefault();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click occurred outside the menu
      if (menuOpen && !event.target.closest('.menu') && !event.target.closest('.menu-icon')) {
        setMenuOpen(false);
      }
    };

    // Add event listener for clicks outside the menu
    document.addEventListener('click', handleOutsideClick);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuOpen, setMenuOpen]);



  const handleLogin = () => {
    if (isEmbeddedBrowser()) {
      alert('שים לב: כדי להבטיח חיבור בטוח לאפליקציה, אנא פתח אותה ישירות בדפדפן האינטרנט שלך.');
      forceExternalBrowser(url);
    }
    loginWithRedirect({
      returnTo: window.location.origin, // Specify the desired return URL
      // Add a callback to handle the login redirect
      onRedirectCallback: (appState) => {
      localStorage.setItem('isLoggedIn', 'true');
        
      }
    });
  };

  return (
    <div className="title-logo-container">
      <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
        
        ☰
      </div>
      {menuOpen && (
        
        <div className="menu">
          {isAuthenticated && (
          <div style={{direction:"rtl"}}>
                        <p>שלום {user.given_name}</p>
                    </div>)}

          <Link  to="/Main" onClick={handleLinkClick}>
            <BsHearts style={{ marginRight: '3px' }} />
            תשובה מהירה
          </Link>
          {!isAuthenticated && (
            
            <button
            
            style={{marginTop:'10px'}}
            className='log-button button0 button2'
            onClick={handleLogin}>התחבר
          </button>
          )}
          {isAuthenticated && (
            <>
              <Link to="/openers" onClick={handleLinkClick}>
                <BsSearchHeartFill style={{ marginRight: '3px' }} />
                משפטי פתיחה
              </Link>
              <Link to="/openersliked" onClick={handleLinkClick}>
                <PiListHeartFill style={{ marginRight: '3px' }} />
                מועדפים
              </Link>
              {/* <Link to="/forum" onClick={handleLinkClick}>
                <MdForum style={{ marginRight: '3px' }} />
                פורום
              </Link> */}
              <Link to="/settings" onClick={handleLinkClick}>
                <AccountCircleIcon style={{ marginRight: '3px' }} />
                פרופיל
              </Link>
              <Link to="/PurchaseToken" onClick={handleLinkClick}>
                <FaFileInvoiceDollar
                  style={{ marginRight: '3px' }} />
                מחירים
              </Link>
              <LogoutButton />
              <Link to="/privacy-policy" onClick={handleLinkClick}> פרטיות</Link>
              <Link to="/terms" onClick={handleLinkClick}> תנאים</Link>
              <QuickAddToHomePage />

            </>
          )}
        </div>
      )}
      {/* <h1 onClick={() => { handleNavigate(); handleLinkClick(); }} style={{ fontFamily: '"Secular One", sans-serif',cursor: 'pointer', fontSize:'larger',marginTop:'5px' }}>ChatMates.AI</h1> */}
      <div>
        <img src={Icon} style={{ width: '70px', height: '60px', cursor: 'pointer' }} onClick={() => { handleNavigate(); handleLinkClick(); }}></img>
      </div>

      {isAuthenticated &&
        <div
          style={{
            fontFamily: '"Secular One", sans-serif',
            fontSize: 'x-large',
            position: 'absolute',
            left: '0',
            marginLeft: '10px'
          }}
        >
          {(daysRemaining > 0 && hoursRemaining > 0) ? (

            <Link to="/settings">
              <img
                src={InfinityStar}
                style={{
                  width: '30px',
                  boxShadow: 'none',
                  marginRight: '10px',
                  marginBottom: '5px'
                }}

                alt="Infinity Star Icon"
              />

            </Link>
          ) : (
            <>
              <img
                src={coin}
                style={{
                  width: '30px',
                  boxShadow: 'none',
                  marginRight: '10px',
                  marginBottom: '5px'
                }}
                alt="Coin Icon"
              />
              {requestLeft !== undefined ? (
                <>
                  {requestLeft > 0 ? requestLeft : 0}
                </>
              ) : (
                0
              )}
            </>
          )}
        </div>
      }
    </div>
  );
};

export default NavBar;
