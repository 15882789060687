import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import React, { useState, useEffect } from 'react';
import DiscreteSliderValues from '../Slider';
import LoadingButtonsTransition from '../Loader';

// import "./assets/vendor/aos/aos.css";
import "./assets/vendor/bootstrap/css/bootstrap-grid.css"
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
import "./assets/img/messages-dream-gold.png";
import messageImage from './assets/img/messages-dream-gold.png';
import newmessageImage from './assets/img/new_messages.png';

import newImage from '../new_image.png'
import Infinity from './infinity.png'
import Flash from './flash.png'
import IphonePopUp from '../IphonePopUp'

// JavaScript imports
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'aos/dist/aos.css';
// import 'glightbox/dist/css/glightbox.min.css';
// import 'swiper/swiper-bundle.min.css';
// import "./assets/js/main";

// import "./assets/vendor/php-email-form/validate";
// import "./assets/vendor/purecounter/purecounter_vanilla";
// import "./assets/vendor/aos/aos";
// import "./assets/vendor/bootstrap/js/bootstrap.bundle";
// import "./assets/vendor/glightbox/js/glightbox";
// // // import "./assets/vendor/swiper/swiper-bundle.min";
// // import Swiper from "./assets/vendor/swiper/swiper-bundle.min.js"



function MainPage() {
  const [isFlashing, setIsFlashing] = useState(true);
  const [zoomEffect, setZoomEffect] = useState(false);
  const [sliderValue, setSliderValue] = useState(50); // State to store slider value
  const [loading, setLoading] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const location = useLocation();
  const [showIphonePopUp, setShowIphonePopUp] = useState(false);
  const navigate = useNavigate();
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setCurrentUrl(window.location.href); // Get the full URL
  }, [location]); // Update the state whenever the location changes

  const sentenceSets = {
    0: ["אני כבר מתרגש מהמפגש שלנו 😊", "חחחח אל תדאגי, עליי", "אני טוב בהפתעות"],
    25: ["את סומכת עליי?", "את כבר עושה לי פרפרים בבטן", "אני הולך להביא את כל החמידות שלי לפגישה 😁"],
    50: ["חחחח נתראה ביום ראשון", "נשמע מעולה, נתראה אז.", "נראלי שאפתיע את עצמי בדייט איתך"],
    75: ["את בטוחה שאת מוכנה למה שאני מתכנן? 😜", "איתגרת אותי.. תתחילי לדאוג.", "זה האיום הכי רומנטי שקיבלתי בחיים"],
    100: ["עכשיו בכוונה אני אדאג שיהיה גרוע", "חחחחחח טוב, נקווה שזה שווה את זה.", "חחח נראה אם זה יפתיע אותי קודם."]
  };

  const [responses, setResponses] = useState(sentenceSets[50]); // Default to 50% sentences
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pendingResponses, setPendingResponses] = useState(sentenceSets[50]); // Store sentences for the next slider value
  const [clickCount, setClickCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);





  const handleGenerateResponse = () => {
    setLoading(true);

    setTimeout(() => {
      const nextIndex = (currentIndex + 1) % responses.length;
      setResponses(pendingResponses); // Update responses to pending ones when button is clicked
      setCurrentIndex(nextIndex);

      setLoading(false);
      const newClickCount = clickCount + 1;
      setClickCount(newClickCount);

      // Show the message after 5 clicks
      if (newClickCount === 5) {
        setShowMessage(true);
      } // Set loading to false after updating the responses
    }, 1500); // 1.5 seconds delay
  };

  const handleSliderChange = (value) => {
    setSliderValue(value); // Update slider value state
    setPendingResponses(sentenceSets[value]); // Update pending responses based on slider value
    setZoomEffect(true);
    // Scroll the page down by 100 pixels (you can adjust this value as needed)
    const targetButton = document.querySelector('.scrolldownbtn');
    if (targetButton) {
      targetButton.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'end', // Scroll to the top of the element
      });
    }
    setIsAnimated(true);
    setTimeout(() => {
      setIsAnimated(false);
    }, 5000); // Adjust this value as needed
  
    // Remove zoom effect after 1 second
    setTimeout(() => {
      setZoomEffect(false);
    }, 1000);
  };



  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFlashing((prevIsFlashing) => !prevIsFlashing);
    }, 15000); // Adjust the flashing interval as needed

    return () => clearInterval(intervalId);
  }, []);


  //   const isEmbeddedBrowser = () => {
  //     const userAgent = navigator.userAgent.toLowerCase();
  //     const embeddedPatterns = ['instagram', 'fban', 'fbav', 'fbios', 'facebook','linkedin','fbclid','fc','tiktok'];
  //     return embeddedPatterns.some(pattern => userAgent.includes(pattern));
  // };

  const isEmbeddedBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const referrer = document.referrer.toLowerCase();
    const width = window.innerWidth;
    const height = window.innerHeight;

    const embeddedPatterns = [
      'instagram', 'facebook', 'fban', 'fbav',
      'fbios', 'linkedin', 'fbclid', 'fc', 'tiktok',
      'twitter', 'snapchat'
    ];

    const isUserAgentMatch = embeddedPatterns.some((pattern) => userAgent.includes(pattern));
    const isReferrerMatch = embeddedPatterns.some((pattern) => referrer.includes(pattern));
    const hasGlobalIndicators =
      typeof window.TikTok !== 'undefined' ||
      typeof window.Instagram !== 'undefined';
    const isViewportUnusual = (width / height) < 0.5 || width < 600;

    return isUserAgentMatch || isReferrerMatch || hasGlobalIndicators || isViewportUnusual;
  };

  const formatUrl = (url) => {
    // Remove any existing protocols and www
    let cleanUrl = url.replace(/^(https?:\/\/)+(www\.)?/, '');
    // Ensure we don't have double slashes
    cleanUrl = cleanUrl.replace(/\/+/g, '/');
    // Add back www. if it's not there
    if (!cleanUrl.startsWith('www.')) {
      cleanUrl = 'www.' + cleanUrl;
    }
    return cleanUrl;
  };



  const checkIphone = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const searchParams = new URLSearchParams(window.location.search); // Access URL search params
    const searchTerms = ['fb'];
    const searchTermsInUrl1 = window.location.search.includes('fb');
    const fallbackUrl = '/?fb';
    const fallbackUrls = '/?fb123';
    // Check if any of the search terms exist in the URL
    const searchTermsInUrl = Array.from(searchParams.keys()).some((term) =>
      searchTerms.includes(term)
    );



    if (searchTermsInUrl || searchTermsInUrl1){
    if (/iphone|ipad|ipod/i.test(userAgent)) {
      if (searchTermsInUrl || searchTermsInUrl1) {
        setShowIphonePopUp(true);
        return true;

      }
    }
    else if (/android/i.test(userAgent)) {
      if (searchTermsInUrl || searchTermsInUrl1) {
        const intent = `intent://www.winkee.dating/Main#Intent;scheme=https;package=com.android.chrome;S.browser_fallback_url=https://www.winkee.dating/Main;end`;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = intent;
        link.style.display = 'none';
        document.body.appendChild(link);

        // Trigger the click programmatically
        link.click();

        // Clean up the element
        setTimeout(() => {
          document.body.removeChild(link);

          // Only redirect if we're still in the original app after a delay
          if (!document.hidden && !document.webkitHidden) {
            console.log("STAY HERE");
            setShowIphonePopUp(true);
            navigate(fallbackUrl);
          }
          else {
            console.log("first else");

            window.location.href = "https://www.winkee.dating/Main";
          }
        }, 1000);
      }}
    }
    else {
      console.log("second else");

      window.location.href = "https://www.winkee.dating/Main";
    }
  }





  const forceExternalBrowser = (url) => {
    const cleanUrl = formatUrl(url);
    const userAgent = navigator.userAgent.toLowerCase();
    const searchParams = new URLSearchParams(window.location.search); // Directly access the URL search params
    const searchTerms = ['fb'];

    // Check if any of the search terms exist in the URL
    const isKeywordInUrl = searchTerms.some((term) => searchParams.has(term));


    if (/iphone|ipad|ipod/i.test(userAgent)) {
      window.location.href = `googlechromes://${cleanUrl}`;
      setTimeout(() => {
        window.location.href = `https://${cleanUrl}`;
      }, 200);
      if (isKeywordInUrl) {
        alert('בשביל להמשיך אנא פתח את הדפדפן בCHROME/SAFARI');
        window.location.href = `https://${cleanUrl}`;
      }
    } else if (/android/i.test(userAgent)) {
      // For Android, use a more reliable approach
      const intent = `intent://${cleanUrl}#Intent;scheme=https;package=com.android.chrome;S.browser_fallback_url=https://${cleanUrl};end`;

      // Create a hidden anchor element
      const link = document.createElement('a');
      link.href = intent;
      link.style.display = 'none';
      document.body.appendChild(link);

      // Trigger the click programmatically
      link.click();

      // Clean up the element
      setTimeout(() => {
        document.body.removeChild(link);

        // Only redirect if we're still in the original app after a delay
        if (!document.hidden && !document.webkitHidden) {
          window.location.href = `https://${cleanUrl}`;
        }
      }, 1000);
    } else {
      window.location.href = `https://${cleanUrl}`;
    }
  };

  const handleCloseIphonePopUp = () => {
    setShowIphonePopUp(false);
  };


  const handleButtonClick = () => {
    // Use the complete URL without /Main
    const baseUrl = 'www.winkee.dating/Main';

    if (isEmbeddedBrowser()) {
      console.log('Detected embedded browser, forcing external browser');
      forceExternalBrowser(baseUrl);
    } else {
      console.log('Already in external browser, proceeding normally');
      window.location.href = "/Main";
    }
  };

  const debugBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    console.log('User Agent:', userAgent);
    console.log('Is Embedded Browser:', isEmbeddedBrowser());
    console.log('Is iOS:', /iphone|ipad|ipod/i.test(navigator.userAgent));
    console.log('Is Android:', /android/i.test(navigator.userAgent));
  };

  // Run debug info on component mount
  useEffect(() => {
    debugBrowserInfo();
  }, []);


  return (
    <>

      <meta content="" name="description" />
      <meta content="" name="keywords" />
      {showIphonePopUp && <IphonePopUp open={showIphonePopUp} handleClose={handleCloseIphonePopUp} />}

      {/* <script src="assets/vendor/php-email-form/validate.js"></script>
      <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
      <script src="assets/vendor/aos/aos.js"></script>
      <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
      <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
      <script src="assets/js/main.js"></script> */}
      {/* <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script> */}

      <link rel="icon" href="assets/img/favicon.png" />
      <link rel="apple-touch-icon" href="assets/img/apple-touch-icon.png" />

      {/*  */}


      <section id="hero">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out" >
                <h1 style={{ direction: "rtl", fontSize: '37px', lineHeight: 'normal' }}>לשדרג את השיחות שלך בעולם ההיכרויות</h1>
                <h2><b style={{ color: 'white' }}>קבל תגובות ופלרטוטים אוטומטיים לשיחות הצ'אט שלך</b></h2>
                <div className="text-center text-lg-start">
                  <Link onClick={checkIphone} className={`btn-get-started scrollto ${isFlashing ? 'flashing' : ''}`}>ניסיון ללא עלות</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
              {/* <img  src="assets/img/messages-dream-gold.png" className="img-fluid animated" alt="" /> */}
              <img src={newmessageImage} className="img-fluid animated" alt="Description" />
            </div>
          </div>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
          </g>
        </svg>

      </section>

      <main id="main" style={{ direction: "rtl" }}>

        <section id="about" className="about">
          <div className="container-fluid">
            <img className='image_check' src={messageImage}></img>
            <div className="row">
              {/* <div >   */}
              {/* <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">

              </div> */}

              <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">

                <h3>מה אנחנו עושים?</h3>
                <p> אנחנו מבינים כמה דייטינג באפליקציות זה תחום מתסכל. לכן יצרנו מערכת שתעשה את זה קל בשבילך.

                  עם התגובות האוטומטיות שלנו תשיג יותר הצלחות, בקלות וללא מאמץ.</p>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <Link onClick={checkIphone} >
                    <div className="icon"><img src={Flash} style={{ width: "40px" }} /></div>
                  </Link>
                  <h4 className="title">מענה מיידי לפי צילום מסך</h4>
                  <p className="description">העלה צילום מסך של השיחה וקבל תגובה מתאימה מה AI שלנו במהירות ושנינות -
                    <b>
                      לעולם לא תתקע בלי תשובה
                    </b>
                  </p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <Link onClick={checkIphone} >
                    <div className="icon"> <img src={Infinity} style={{ width: "40px" }} /></div>
                  </Link>
                  <h4 className="title">שינויים ללא הגבלה</h4>
                  <p className="description">תוכל לשנות את התגובה של הAI כמה פעמים שרק תרצה עד שתמצא תגובה שמספיק טובה לך על בסיס השיחה בצילום המסך שהעלת</p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <Link onClick={checkIphone} >
                    <div className="icon"><i className="bx bx-gift"></i></div></Link>
                  <h4 className="title">משפטי פתיחה מותאמים אישית</h4>
                  <p className="description">אינספור משפטי פתיחה לפי נושאים, כדי שתוכל להתחיל בקלות כל שיחה חדשה באפליקציות ההיכירויות</p>
                </div>

                <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <Link onClick={checkIphone} >
                    <div className="icon"><i className="bx bx-atom"></i></div></Link>
                  <h4 className="title">מחסן קסמים</h4>
                  <p className="description">תוכל לשמור את כל המשפטים שאהבת מהAI והם יהיו זמינים לך בכל רגע נתון בפרופיל האישי שלך לשימוש חוזר</p>
                </div>

                <Button style={{
                  border: "2px dashed #ccc",
                  padding: "15px",
                  borderRadius: "10px",
                  fontSize: '20px',
                  backgroundColor: '#1acc8d'
                }} className={`custom-button ${isFlashing ? ' flashing' : ''}`}>
                  <Link onClick={checkIphone} style={{ color: "white" }}>ניסיון ללא עלות</Link>
                </Button>

              </div>
            </div>

          </div>
        </section>


        <section style={{ backgroundColor: 'rgb(154, 23, 242)', paddingBottom: '0px' }} id="features" className="features">

          <div className="container">
            <div className="video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">


              <div className="col-md-4" data-aos="fade-right">
                <div className="section-title" data-aos="fade-up">
                  <p style={{ color: 'aliceblue' }}>מד חריפות</p>
                  <h3 style={{ color: 'aliceblue' }}>תתחיל לתבלן את ההודעות שלך בקצת טעם!</h3>
                </div>
                <img src={newImage} className="img-fluid" style={{ width: '280px' }} alt="" />

                {/* NEWWWWW */}

                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  direction: 'ltr',
                }}>
                  <DiscreteSliderValues onChange={handleSliderChange} />
                  <h1 style={{ marginTop: '10px' }}>
                    תבחר לך מצב רוח
                  </h1>
                  <div className="response-container" style={{ marginTop: '20px' }}>
                    <div style={{ direction: "rtl", fontWeight: 600 }}>
                      {responses[currentIndex]}
                    </div>
                    <div style={{ fontSize: 'small', marginTop: '20px', color: 'gray' }}>
                      זוהי רק הצעה
                    </div>
                  </div>

                  <LoadingButtonsTransition
                    onClick={handleGenerateResponse}
                    loading={loading}
                    isAnimated={isAnimated}
                    buttonLabel="תגובה חדשה"
                    style={{
                      transform: zoomEffect ? 'scale(1.4)' : 'scale(1)',
                      transition: 'transform 0.9s ease-in-out',
                    }}
                  />
                  {showMessage && (
                    <p style={{ marginTop: '20px' }}>ניסינו מספיק לא?
                      בוא תנסה את הדבר האמיתי
                      <p>
                        <Link onClick={checkIphone} style={{ color: "white" }}>לחץ וגלה עכשיו</Link>
                      </p>
                    </p>
                  )}
                </div>
              </div>





            </div>

          </div>

        </section>
        <Button style={{
          border: "2px dashed #ccc",
          padding: "15px",
          borderRadius: "10px",
          fontSize: '20px',
          backgroundColor: '#1acc8d',
          margin: '10px'
        }} className={`scrolldownbtn custom-button ${isFlashing ? ' flashing' : ''}`}>
          <Link onClick={checkIphone} style={{ color: "white" }}>ניסיון ללא עלות</Link>
        </Button>
        <section id="counts" className="counts">
          <div className="container">

            <div className="row" data-aos="fade-up">

              <div className="col-lg-3 col-md-6">
                <div className="count-box">
                  <i className="bi bi-emoji-smile"></i>
                  <span data-purecounter-start="0" data-purecounter-end="247" data-purecounter-duration="1" className="purecounter"></span>
                  <p>500+ לקוחות מרוצים</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div className="count-box">
                  <i className="bi bi-journal-richtext"></i>
                  <span data-purecounter-start="0" data-purecounter-end="200" data-purecounter-duration="1" className="purecounter"></span>
                  <p>מעל ל200+ משפטי פתיחה</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-headset"></i>
                  <span data-purecounter-start="0" data-purecounter-end="7463" data-purecounter-duration="1" className="purecounter"></span>
                  <p>משפטי AI שנשמרו</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-people"></i>
                  <span data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1" className="purecounter"></span>
                  <p>10+ שנות נסיון בדייטינג</p>
                </div>
              </div>

            </div>

          </div>
        </section>

        <section id="details" className="details">
          <div className="container">
            <div className="row content">
              <div className="col-md-4" data-aos="fade-right">
                <img src="assets/img/details-1.png" className="img-fluid" alt="" />
              </div>
              <div className="col-md-8 pt-4" data-aos="fade-up">
                <h3>עם כלים חכמים וטכנולוגיה מתקדמת, אין יותר מה לדאוג – ההודעה הבאה שלך יכולה להיות הפתח לקשר שחלמת עליו</h3>
                <p className="fst-italic">

                </p>
                <ul>
                  <li><i className="bi bi-check"></i> היחידים בעולם שמספקים שירותי AI לדייטינג בעברית </li>
                  <li><i className="bi bi-check"></i> תגובה חכמה על בסיס כל השיחה שלך</li>
                  <li><i className="bi bi-check"></i> שנים של נסיון בעולם הדייטינג וההיכירויות</li>
                  <li><i className="bi bi-check"></i> הרשמה ללא עלות עם 5 נסיונות מתנה</li>
                  <li><i className="bi bi-check"></i> שימוש קל מהיר ופשוט לכל משתמש</li>
                </ul>
                <h2>
                  התחל לשדרג את השיחות שלך כבר היום – הרשמו בחינם ותתחילו לראות את ההבדל
                </h2>
              </div>
            </div>
          </div>

        </section>
        <Button style={{
          border: "2px dashed #ccc",
          padding: "15px",
          borderRadius: "10px",
          fontSize: '20px',
          backgroundColor: '#1acc8d'
        }} className={`custom-button ${isFlashing ? ' flashing' : ''}`}>
          <Link onClick={checkIphone} style={{ color: "white" }}>ניסיון ללא עלות</Link>
        </Button>
        <section id="pricing" className="pricing">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>מחירים</h2>
              <p>המחירים שלנו</p>
            </div>
            <div className="row" data-aos="fade-left">
              <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                <div className="box featured" data-aos="zoom-in" data-aos-delay="200">
                  <h3 style={{ fontSize: 'x-large' }}>סקרן</h3>
                  <h4><sup>₪</sup>15<span> / 3 ימים</span></h4>
                  <ul>
                    <li>תשלום חד פעמי ל3 ימים לא מתחדש אוטומטי</li>
                    <li>לסקרנים שמחפשים לגלות את העולם של הפלירטוט האוטומטי בלי התחייבות ארוכה. כי לפעמים כדאי לבדוק את המים לפני שקופצים!</li>
                    <li>- תשובות לשיחות מותאמות אישית בעזרת - AI</li>
                    <li>- מאגר משפטי פתיחה יצירתיים לכל סיטואציה אפשרית</li>
                    <li>- שמירה ללא הגבלה של תשובות ומועדפים</li>
                  </ul>
                  <div className="btn-wrap">
                    <Link onClick={checkIphone} className="btn-buy">אני רוצה<i className="bx bx-chevron-left"></i></Link>
                  </div>
                </div>
              </div>


              <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                <div className="box featured" data-aos="zoom-in" data-aos-delay="200">

                  <span className="advanced">פופולרי</span>
                  <h3 style={{ fontSize: 'x-large' }}>אלוף</h3>
                  <h4><sup>₪</sup>28<span> / 7 ימים</span></h4>
                  <ul>
                    <li>תשלום חד פעמי ל7 ימים לא מתחדש אוטומטי</li>
                    <li>לאלופים שמחפשים שבוע של הצלחות רומנטיות מהירות. למה להתאמץ כשאפשר להיות אלוף בקלות?</li>
                    <li>- תשובות לשיחות מותאמות אישית בעזרת - AI</li>
                    <li>- מאגר משפטי פתיחה יצירתיים לכל סיטואציה אפשרית</li>
                    <li>- שמירה ללא הגבלה של תשובות ומועדפים</li>
                  </ul>
                  <div className="btn-wrap">
                    <Link onClick={checkIphone} className="btn-buy">אני רוצה<i className="bx bx-chevron-left"></i></Link>
                  </div>
                </div>
              </div>




              <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                <div className="box featured" data-aos="zoom-in" data-aos-delay="200">
                  <h3 style={{ fontSize: 'x-large' }}>מאסטר</h3>
                  <h4><sup>₪</sup>49<span> / 30 ימים</span></h4>
                  <ul>
                    <li>תשלום חד פעמי ל30 ימים לא מתחדש אוטומטי</li>
                    <li>למאסטרים של הפלירטוט, אלו שמכירים את המשחק ורוצים להקל על עצמם עם כלי אוטומטי</li>
                    <li>- תשובות לשיחות מותאמות אישית בעזרת - AI</li>
                    <li>- מאגר משפטי פתיחה יצירתיים לכל סיטואציה אפשרית</li>
                    <li>- שמירה ללא הגבלה של תשובות ומועדפים</li>
                    <li>-גישה מוקדמת לפיצ'רים הכי חדשים ומתקדמים לפני כולם</li>
                  </ul>
                  <div className="btn-wrap">
                    <Link onClick={checkIphone} className="btn-buy">אני רוצה<i className="bx bx-chevron-left"></i></Link>

                  </div>
                </div>


              </div>
              {/* </div> */}
            </div>
          </div>
        </section>

        <section id="faq" className="faq section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>F.A.Q</h2>
              <p>שאלות ותשובות</p>
            </div>
            <div className="faq-list">
              <ul>
                <li data-aos="fade-up">
                  {/* <i className="bx bx-help-circle icon-help"></i>  */}
                  <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">מדובר על מנוי המתחדש אוטומטית או מנוי חודשי? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                    <p>
                      ממש לא. התשלום הוא עבור חבילת השימושים שאותם תוכל לנצל בזמנך וכרצונך. אתה תחוייב רק על החבילה שרכשת, אם זה ל-3 ימים, שבוע או חודש. הכמות אינה מתחדשת אוטומטית ויש לחדש אותה בעצמך. בנוסף, תוכל להשתמש ללא הגבלה במשך הזמן שרכשת. אצלנו אין תשלומים אוטומטיים או חודשיים, הכל בשליטתך המלאה! 😊                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  {/* <i className="bx bx-help-circle icon-help"></i> */}
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">האם ניתן לבטל כמות שימושים שרכשתי? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-3" className="collapse show" data-bs-parent=".faq-list">
                    <p>
                      אנחנו מבטיחים לעשות כל שביכולתינו כדי לשמור על שביעות רצונך. ואנחנו כן נעשה החזר כספי במידת הצורך . ניתן לפנות אלינו בכתובת ״winkee.dating@gmail.com"
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="300">
                  {/* <i className="bx bx-help-circle icon-help"></i> */}
                  <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">איך ניתן לדבר עם נציג? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-4" className="collapse show" data-bs-parent=".faq-list">
                    <p>
                      ניתן לפנות אלינו בכל יום לכתובת המייל  : winkee.dating@gmail.com
                    </p>
                  </div>
                </li>

                {/* <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed"> <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                  <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                    <p>
                    </p>
                  </div>
                </li> */}
              </ul>




            </div>

            <Button style={{
              border: "2px dashed #ccc",
              padding: "15px",
              borderRadius: "10px",
              fontSize: '20px',
              backgroundColor: '#1acc8d'
            }} className={`custom-button ${isFlashing ? ' flashing' : ''}`}>
              <Link onClick={checkIphone} style={{ color: "white" }}>ניסיון ללא עלות</Link>
            </Button>
          </div>

        </section>

        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>צור קשר</h2>
              <p>יצירת קשר</p>
            </div>
            <div className="row">
              <div className="col-lg-4" data-aos="fade-right" data-aos-delay="100">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>מיקום:</h4>
                    <p>Tel Aviv Israel</p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>אימייל:</h4>
                    <p>winkee.dating@gmail.com</p>
                  </div>

                  {/* <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+1 5589 55488 55s</p>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
                <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                  </div>
                  <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                  </div>
                  <div className="text-center"><button type="submit">Send Message</button></div>
                </form>
              </div> */}

            </div>
          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">


                  <p>
                    {/* <strong>Phone:</strong> +1 5589 55488 55<br /> */}
                    <strong>Email:</strong> winkee.dating@gmail.com<br />
                  </p>
                  <div className="social-links mt-3">
                    {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                    <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
                    <a href="https://www.instagram.com/winkee.dating/" target='blank' className="instagram"><i className="bx bxl-instagram"></i></a>
                    {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                    <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
                  </div>
                </div>

              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>קישורים</h4>
                <ul style={{ direction: "rtl" }}>
                  <li> <Link onClick={checkIphone}>אפליקציה</Link><i className="bx bx-chevron-left"></i></li>
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li> */}
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Services</a></li> */}
                  <li> <Link to="/terms">תנאי שימוש</Link><i className="bx bx-chevron-left"></i></li>
                  <li> <Link to="/privacy-policy">פרטיות</Link><i className="bx bx-chevron-left"></i></li>
                </ul>
              </div>
              {/* <div className="col-lg-2 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                </ul>
              </div> */}

            </div>
          </div>
        </div>
        <div className="container">
          {/* <Link to="/"  onClick={scrollToTop}>חזור למעלה<i className="bi bi-arrow-up-short"></i></Link> */}
          <a href="">חזור למעלה<i className="bi bi-arrow-up-short"></i></a>


          <div className="copyright">
            &copy; Copyright <strong><span>Winkee.dating</span></strong>. All Rights Reserved

          </div>
          <div className="credits">
          </div>
        </div>
      </footer>

      {/* <div id="preloader"></div> */}

      {/* <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
  <script src="assets/vendor/aos/aos.js"></script>
  <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="assets/vendor/php-email-form/validate.js"></script>
  <script src="assets/js/main.js"></script> */}


    </>
  );
}

export default MainPage;